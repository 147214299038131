export const balladeGallerieV =[
    {
        src:'IMG7163.jpg',
        desc:'Helgoland, en ville basse, si j´ ose dire.'
    },
    {
        src:'scrat_3.jpg',
        desc:'Une petite tasse de café en terrasse, avant de partir de Helgoland'
    },
    {
        src:'scrat_2.jpg',
        desc:'Sud de l´ Allemagne, près de la frontière Suisse.'
    },
    {
        src:'IMG7386.jpg',
        desc:'Cuxhaven plage. On reconnaît le Kugelbake en arrière-plan. La limite entre le fleuve Elbe et la mer. Il servait autrefois de phare, aujourd´hui, c´est plus une attraction touristique qu´autre chose.'
    },
    {
        src:'IMG7515.jpg',
        desc:'Danemark - et finir sur un nez. Le parc comporte une multitude de sculptures en bois, en métal sans compter les ouvrages en sable.'
    },
    {
        src:'IMG7531.jpg',
        desc:'Danemark, la plage de Løkken.'
    },
    {
        src:'scrat_4.jpg',
        desc:'Malte, en arrière-plan: Il-Batterija ta´ Santa Marija.'
    },
    {
        src:'scrat_5.jpg',
        desc:'Lütetsburg, magnifique parc et château qui reste privé et donc non visitable.'
    },
    {
        src:'scrat_6.jpg',
        desc:'Jever, on y produit de la bière très amère. La fontaine est articulée et change d´ allure au gré des passants.'
    }      
]